<template>
	<div id="orangelogin">
		<div class="loginbox">
			<div class="titlebox">				
				<span>{{shop_name||''}}</span>
				<span v-if="guard_name=='hous_keep_admin_user'&&type==1">代理</span>
				<span v-if="guard_name=='hous_keep_admin_user'&&type==2">商家</span>
				<span>登录后台</span>			
			</div>
			
			<div class="inputbox" style="margin-bottom: 40px;">
				<div class="label">登录账号</div>
				<el-input type="text" style="input" v-model="account_number" placeholder="请输入账号"></el-input>
			</div>
			
			<div class="inputbox">
				<div class="label">登录密码</div>
				<el-input type="password" style="input" v-model="password" placeholder="请输入密码"></el-input>
			</div>
			
			<el-button class="loginbtn" type="primary" @click='login'>登录</el-button>
		</div>
	</div>
</template>

<script>
import { eltips,getUrlKey } from '@/util/util.js';
import { tim,imInit,IMlogin,TIM } from '@/util/im.js';
import {resetRouter} from '@/router/index.js'
import store from '@/store/index.js'
export default {
	name: 'orangelogin',
	data() {
		return {
			shop_name:'',
			account_number:'',//用户名
			password:'',//密码
			type:'', //1、代理 2、商家 3、物业
			guard_name:'',//应用名称
			shop_id:'',
		};
	},
	created() {
		if(getUrlKey('type')){
			this.type = getUrlKey('type')
		}
		
		if(getUrlKey('name')){
			this.shop_name = getUrlKey('name')
		}
		
		if(getUrlKey('shop_id')){
			this.shop_id = getUrlKey('shop_id')
		}
		
		if(getUrlKey('guard_name')){
			this.guard_name = getUrlKey('guard_name')
		}
		// 初始化
		imInit(1400635745,1)
	},
	methods:{
		//登录
		login(){
			if(!this.account_number){
				eltips('请输入用户名','error')
				return
			}
			if(!this.password){
				eltips('请输入密码','error')
				return
			}
			
			sessionStorage.setItem('guardName','api')
			
			// let data = {
			// 	shop_id:this.shop_id,
			// 	account_number:this.account_number,
			// 	password:this.password,
			// 	is_customer:1,
			// 	type:1,
			// }
			// if(this.guard_name){
			// 	data.guard_name = this.guard_name
			// }
			
			// this.axios.post('/api/login',data).then(res=>{
			// 	if(res.code==200){
			// 		sessionStorage.setItem('token',res.data.access_token)
			// 		sessionStorage.removeItem('role_id')
			// 		sessionStorage.removeItem('pluginroute')
			// 		sessionStorage.removeItem('pluginid')
			// 		this.getuserinfo()
			// 	}else{
			// 		eltips(res.msg,'error')
			// 	}
			// })
			
			let data={
				username:this.account_number,
				password:this.password,
				shop_id:25079057,
			}
			
			this.axios.post('/api/orange_manage/auth/login',data).then(res=>{
				if(res.code==200){
					sessionStorage.setItem('token',res.data)
					sessionStorage.removeItem('role_id')
					sessionStorage.removeItem('pluginroute')
					sessionStorage.removeItem('pluginid')
					this.getuserinfo()
				}else{
					eltips(res.msg,'error')
				}
			})
			
		},
		//获取个人信息
		getuserinfo(){
			this.axios.get('/api/me',{params:{
				guard_name:'orange_admin_user'
			}}).then(res=>{
				if(res.code==200){
					
					sessionStorage.setItem('userinfo',JSON.stringify(res.data))
					sessionStorage.setItem('guardName',res.data.guard_name)
					if(res.data.user){
						if(res.data.user.unitid==2){
							sessionStorage.setItem('role_id', res.data.roles[0].id);
						}
					}
					
					console.log(res,'res')
					this.getimtoken()
					
					this.getmeuns()
				}
			})
		},
		
		// 获取im的token
		getimtoken(){
			this.axios.get('/api/orange_manage/user/imUserSig',{params:{
				guard_name:'orange_admin_user'
			}}).then(res=>{
				if(res.code==200){
					console.log(res,'IMtoken')
					sessionStorage.setItem('IMtoken',res.data)
					
					IMlogin(JSON.parse(sessionStorage.getItem('userinfo')).im_account,res.data)
					
					
					// this.$tim
					//   .login({
					//     userID: JSON.parse(sessionStorage.getItem('userinfo')).im_account,
					//     userSig: res.data
					//   })
					//   .then((rex) => {
					// 	console.log(rex,'----')
						  
					//     // this.loading = false
					//     // this.$store.commit('toggleIsLogin', true)
					    
					//     // eltips(res.msg,'error')
						
					// 	// this.$router.push({path:'/index'});
					//   })
					//   .catch(error => {
					//     // this.loading = false
					    
					//   })
				}
			})
		},
		
		//获取权限菜单
		getmeuns(){
			this.axios.get('/api/get_menu').then(res=>{
				resetRouter()
				this.$store.commit('routestatus', 0)
				sessionStorage.removeItem('routestext')
				sessionStorage.removeItem('routes')
				sessionStorage.setItem('routes',JSON.stringify(res.data))
				
				let path
				if(res.data[0].path){
					path = res.data[0].path
				}else{
					let child = res.data[0]._child
					for(let i=0;i<child.length;i++){
						if(child[i].path){
							path = child[i].path
							break 
						}
					}
				}
				console.log(path)
				this.$router.replace({path: path})
				
			})
		},
	}
};
</script>


<style>
	#orangelogin .el-input__inner{
		border: none !important;
	}
	
	#orangelogin .el-button{
		padding: 18px 20px !important;
		font-size: 16px;
		font-weight: bold;
	}
</style>
<style scoped lang="less">
#orangelogin{
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: url(../../assets/images/login_bg.png);
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 4px 4px 2px rgba(0,0,0,.1);
	.loginbox{
		width: 380px;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 30px;
	}
	.titlebox{
		font-size: 20px;
		color: #1E92FF;
		margin-bottom: 40px;
		font-weight: bold;
	}
	.inputbox{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #DEDEDE;
		padding-bottom: 6px;
		.label{
			width: 80px;
		}
		.input{
			
		}
	}
	.loginbtn{
		margin-top: 160px;
		width: 100%;
	}
}
</style>

